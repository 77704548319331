import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';
import { CompaniesHouseResultModel } from '../../../../../models/CompaniesHouseResultModel';
import { CounterFraudResultModel, ProspectResultModel } from '../../../../../models/CounterFraudResultModel';
import { RequestData } from '../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-dashboard-two-business',
  templateUrl: './dashboard-two-business.component.html',
  styleUrls: ['./dashboard-two-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class DashboardTwoBusinessComponent implements OnInit {

  @Input() business: BusinessResultModel = new BusinessResultModel();
  @Input() companiesHouse: CompaniesHouseResultModel = new CompaniesHouseResultModel();
  @Input() prospect: ProspectResultModel = new ProspectResultModel();
  @Input() requestData: RequestData = new RequestData();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public haveDisqualifiedDirectors(): boolean {
    try {
      if (this.business?.creditsafeResult?.report?.directors?.currentDirectors &&
        this.business?.creditsafeResult?.report?.directors?.currentDirectors.length > 0 &&
        this.business?.creditsafeResult?.report?.directors?.currentDirectors.some(x => x.additionalData.disqualified == true)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public isLimitedCompany(): boolean {
    if (
      ((this.requestData.profileData.companyNumber && this.requestData.profileData.companyName) || (this.requestData.profileData.companyName && !this.requestData.profileData.companyNumber) || this.requestData.profileData.companyNumber) &&
      ((this.business.creditsafeResult && !this.business.businessNonRegisteredProfileResult) ||
      (this.business?.responseData?.businessTargeterObject && this.business?.responseData?.businessTargeterObject?.legalStatus == 'L'))
    ) {
      return true;
    }
    return false;
  }

  public isNonLimitedCompany(): boolean {
    if (
      (!this.requestData.profileData.companyNumber) &&
      ((this.business.businessNonRegisteredProfileResult && !this.business.creditsafeResult) ||
      (this.business?.responseData?.businessTargeterObject && this.business?.responseData?.businessTargeterObject?.legalStatus == 'N'))
    ) {
      return true;
    }
    return false;
  }

}
