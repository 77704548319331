import { Component, Input, OnInit } from '@angular/core';
import { BusinessSocialMediaResultModel, SocialMediaResultModel } from '../../../../../models/SocialMediaResultModel';
import { MessagingService } from 'src/app/demo/V3/services/messaging.service';

@Component({
  selector: 'app-socialmedia-result-business',
  templateUrl: './socialmedia-result-business.component.html',
  styleUrls: ['./socialmedia-result-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class SocialmediaResultBusinessComponent implements OnInit {

  @Input() socialMedia: BusinessSocialMediaResultModel = new BusinessSocialMediaResultModel();
  
  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
