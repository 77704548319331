import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { VATResultModel } from '../../../../../models/VATResultModel';

@Component({
  selector: 'app-vat-result-business',
  templateUrl: './vat-result-business.component.html',
  styleUrls: ['./vat-result-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class VatResultBusinessComponent implements OnInit {

  @Input() vatResult: VATResultModel = new VATResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
