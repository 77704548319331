import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { CounterFraudResultModel } from '../../../../../models/CounterFraudResultModel';

@Component({
  selector: 'app-prospect-dashboard-business',
  templateUrl: './prospect-dashboard-business.component.html',
  styleUrls: ['./prospect-dashboard-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class ProspectDashboardBusinessComponent implements OnInit {

  @Input() counterFraud: CounterFraudResultModel = new CounterFraudResultModel();

  constructor(public messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
