import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BusinessSearchResultModel } from 'src/app/demo/V3/search-pages/models/AustraliaResultModel/BusinessSearchResultModel';
import { MessagingService } from 'src/app/demo/V3/services/messaging.service';

@Component({
  selector: 'app-au-business-result',
  templateUrl: './au-business-result.component.html',
  styleUrls: ['./au-business-result.component.css']
})
export class AuBusinessResultComponent implements OnInit, OnChanges  {

  @Input() businessResultDataModel: BusinessSearchResultModel = new BusinessSearchResultModel();
  @Input() searchType: string = null;

  constructor(private messagingService: MessagingService) { }
  
  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    // if(changes['searchType'].currentValue != null) {
    //   this.searchType = changes['searchType'].currentValue; 
    // }

  }


  getLegalNames(legal?: any): string {
    let target:any = null;
    if(this.searchType == 'SearchByABN'){
      target = legal || this.businessResultDataModel?.responseData?.response?.businessEntity202001?.legalName;
      if (!target) return '';
    } else if(this.searchType == 'SearchByASIC'){
      target = legal || this.businessResultDataModel?.responseData?.response?.businessEntity201408?.legalName;
      if (!target) return '';
    }
  
    return [
      target?.familyName,
      target?.givenName,
      target?.otherGivenName
    ]
      .filter(name => name !== null && name !== undefined)
      .join(', ');
  }
  

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
  }

  getNormalizedMainName(): any[] {
    let mainName:any = null;
    if(this.searchType == 'SearchByABN'){
      mainName = this.businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName;
    } else if(this.searchType == 'SearchByASIC') {
      mainName = this.businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName;
    }
  
    // If mainName is null/undefined or not an object, return an empty array
    if (!mainName || typeof mainName !== 'object') {
      return [];
    }
  
    // If mainName is an array, return it as-is
    if (Array.isArray(mainName)) {
      return mainName;
    }
  
    // If mainName is a single object, wrap it in an array
    return [mainName];
  }

}
