<header *ngIf="isShowHeader" class="header material-header" [ngClass]="{'header-settings': settingsPage === true}">
  <nav class='navbar navbar-expand-sm navbar-toggleable-sm navbar-light box-shadow'>
    <div class="container mt-2">
      <a class="" href="https://www.hut.global" target="_blank"><img height="81" src="../assets/Images/headsupLogo.png" width="212"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation"
              >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
        <ul class="navbar-nav flex-grow">
          <li *ngIf="!loggedIn" class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <button type="submit" class="btn  red-grad-button" [routerLink]='["/Login"]'>Login</button>
          </li>
          <li *ngIf="!settingsvisibility && loggedIn" class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <button type="button" class="btn  red-grad-button" (click)="toHome()">Home</button>
          </li>
          <li *ngIf="loggedIn" class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <button type="button" class="btn  red-grad-button" (click)="logout()">Logout</button>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</header>

<router-outlet></router-outlet>

<footer *ngIf="isShowFooter" class="footer" [ngClass]="{'footer-settings': settingsPage === true}">
  <div class="container">
    <!-- <p><small>Designed & Maintained by: HeadsUp Technologies Limited. Company registered in England & Wales number 12968519.</small></p>-->
    <div class="row">
      <div class="col-md-5 text-left">
        <div><small>Copyright © 2025, HeadsUp Technologies Limited</small></div>
        <!--<div><small>Registered office at Unit 13, Redhill 23 Business Park, Redhill, Surrey RH1 2GD</small></div>-->
        <div><small>Company registered in England & Wales number 12968519</small></div>
      </div>
      <div class="col-md-3 text-center mt-1">
        <img src="assets/Images/HUTlogowhite.svg" class="attachment-medium size-medium" alt="" loading="lazy" height="25.6" width="132.4" />
      </div>
      <div class="col-md-4 text-right mt-1">
        <!--<a class="link-decoration" style="color:#ffffff;" routerLink="/cookie-policy" target="_blank">
          <small>Cookie Policy</small>
        </a>&nbsp;&nbsp;-->
        <a class="link-decoration" href="https://www.hut.global/compliance/" target="_blank">
          <small>Privacy Policy</small>
        </a>&nbsp;&nbsp;<a class="link-decoration" href="https://www.hut.global/contact/" target="_blank"><small>Contact</small></a>
      </div>
    </div>
  </div>
</footer>

