import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessagingService } from 'src/app/demo/V3/services/messaging.service';
import { SearchService } from 'src/app/demo/V3/services/search.service';
import { AccessData, ProfileData, RequestData } from '../../../models/SearchRequestModel';
import { SearchType } from '../../../models/SearchType';
import { Shared } from 'src/app/demo/V3/services/shared';
import { Item } from '../../../models/Items';
import { AustraliaSearchService } from 'src/app/demo/V3/services/australia.search.service';
import { BusinessSearchResultModel } from '../../../models/AustraliaResultModel/BusinessSearchResultModel';
declare var $: any;

@Component({
  selector: 'app-au-business',
  templateUrl: './au-business.component.html',
  styleUrls: ['./au-business.component.css','../../shared/shared.searchpage.css']
})
export class AuBusinessComponent implements OnInit {
  @Output() disableExitEventEmitter = new EventEmitter();
  public businessForm: FormGroup;
  public saveAsPDFButtonVisibility: boolean = true;
  public loggedInUsername: string = "";
  public showResultTab: boolean = false;
  public searchType: string = null;
  public businessPostcodeRegex = /^(0[289][0-9]{2})$|^([1-9][0-9]{3})$/gm;
  public validation: any = {
    'reference': Validators.required,
    'required': Validators.required,
    'businessPostcode': Validators.pattern(this.businessPostcodeRegex),
    'abn': Validators.pattern(/^\s*[1-9](\s*\d){10}\s*$/gm),
    'asic':Validators.pattern(/^\s*[0-9](\s*\d){8}\s*$/gm) 
  };

  public businessSearchResultData: BusinessSearchResultModel = new BusinessSearchResultModel();
  public accessData: AccessData = new AccessData();
  public clearSearchButtonVisibility: boolean = true;

  constructor(private elementRef: ElementRef,
      private formBuilder: FormBuilder,
      private searchService: SearchService,
      private australiaSearchService: AustraliaSearchService,
      private messagingService: MessagingService,
      private confirmationService: ConfirmationService,
      private router: Router) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "BUSINESS")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.businessForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      businessName: [""],
      businessPostcode: [""],
      abn: [""],
      asic: [""],
    });
    this.saveAsPDFButtonVisibility = false;
    this.disableExitEventEmitter.emit(false);
    this.clearSearchButtonVisibility = false;
    this.getLicenceItems();
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  businessSearch() {
    this.showResultTab = false;
    this.addValidators();
    this.businessForm.updateValueAndValidity();
    this.validationCheck();

    // console.log(this.businessForm);

    if (this.businessForm.valid) {
      Promise.resolve(this.getRandomString(10)).then(
        (data) => { 
          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          this.setActiveResultTab();

          this.GetABRSearchByName(requestObj);

          this.GetSearchByABN(requestObj);

          this.GetSearchByASIC(requestObj);
      });
    }
  }

  public GetABRSearchByName(requestObj: RequestData): void {
    // this.businessSearchResultData.isLoading = false;
    this.businessSearchResultData.noRelevantData = false;
    this.businessSearchResultData.noSearchSelect = false;
    this.businessSearchResultData.responseData = null;
    this.businessSearchResultData.searchFail = false;
    // this.businessSearchResultData.validation = false;
    this.disableExitEventEmitter.emit(false);

    if (requestObj.profileData.companyName && requestObj.profileData.companyPostalCode) {
      this.businessSearchResultData.isLoading = true;
      this.disableExitEventEmitter.emit(true);
      this.australiaSearchService.GetABRSearchByName(requestObj).subscribe(data => {
        this.businessSearchResultData.isLoading = false;
        this.searchType = "ABRSearchByName";
        this.disableExitEventEmitter.emit(false);
        // console.log(data.data);
        if (!data || !data.data || data.data.length == 0) {
          this.businessSearchResultData.noRelevantData = true;
          return;
        }
        if (data.status == 200)
          this.businessSearchResultData.responseData = JSON.parse(data.data);
          if (this.businessSearchResultData.responseData.response.exception != null && this.businessSearchResultData.responseData.response.exception.exceptionDescription != null) {
            this.businessSearchResultData.noRelevantData = true;
          }
        console.log(this.businessSearchResultData.responseData);
      }, error => {
        this.businessSearchResultData.isLoading = false;
        this.businessSearchResultData.searchFail = true;
        this.disableExitEventEmitter.emit(false);
        console.log(error);
      });
    }
  }

  public GetSearchByABN(requestObj: RequestData): void {
    if(requestObj.profileData.abn) {
      this.businessSearchResultData.isLoading = true;
      this.disableExitEventEmitter.emit(true);
      this.australiaSearchService.GetSearchByABN(requestObj).subscribe(data => {
        this.businessSearchResultData.isLoading = false;
        this.searchType = "SearchByABN";
        this.disableExitEventEmitter.emit(false);
        // console.log(data);
        if (!data || !data.data || data.data.length == 0) {
          this.businessSearchResultData.noRelevantData = true;
          return;
        }
        if (data.status == 200)
          this.businessSearchResultData.responseData = JSON.parse(data.data);
          if (this.businessSearchResultData.responseData.response.exception != null && this.businessSearchResultData.responseData.response.exception.exceptionDescription != null) {
            this.businessSearchResultData.noRelevantData = true;
          }
        console.log(this.businessSearchResultData.responseData);
      }, error => {
        this.businessSearchResultData.isLoading = false;
        this.businessSearchResultData.searchFail = true;
        this.disableExitEventEmitter.emit(false);
        console.log(error);
      });
    }
  }

  public GetSearchByASIC(requestObj: RequestData): void {
    if(requestObj.profileData.asic){
      this.businessSearchResultData.isLoading = true;
      this.disableExitEventEmitter.emit(true);
      this.australiaSearchService.GetSearchByASIC(requestObj).subscribe(data => {
        this.businessSearchResultData.isLoading = false;
        this.searchType = "SearchByASIC";
        this.disableExitEventEmitter.emit(false);
        // console.log(data);
        if (!data || !data.data || data.data.length == 0) {
          this.businessSearchResultData.noRelevantData = true;
          return;
        }
        if (data.status == 200)
          this.businessSearchResultData.responseData = JSON.parse(data.data);
          if (this.businessSearchResultData.responseData.response.exception != null && this.businessSearchResultData.responseData.response.exception.exceptionDescription != null) {
            this.businessSearchResultData.noRelevantData = true;
          }
        console.log(this.businessSearchResultData.responseData); 
      }, error => {
        this.businessSearchResultData.isLoading = false;
        this.businessSearchResultData.searchFail = true;
        this.disableExitEventEmitter.emit(false);
        console.log(error);
      });
    }
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.businessForm.patchValue({
          audit: data
        });
      });
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.businessForm.get('audit').value;
    profileData.referenceNumber = this.businessForm.get('reference').value;
    profileData.companyName = this.businessForm.get('businessName').value;
    profileData.companyPostalCode = this.businessForm.get('businessPostcode').value;
    profileData.abn = this.businessForm.get('abn').value;
    profileData.asic = this.businessForm.get('asic').value;

    profileData.searchType = SearchType.Business.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = this.accessData;
    return requestObj;
  }

  restrictCharacters(event: KeyboardEvent): void {
    // Allow only digits
    const allowedCharacters = /^[0-9]$/;
  
    // Get the key pressed
    const key = event.key;
  
    // Prevent the input if the key doesn't match the allowed characters
    if (!allowedCharacters.test(key)) {
      event.preventDefault();
    }
  }
  
  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.businessForm.controls) {
      this.businessForm.get(control).clearValidators();
      this.businessForm.get(control).setValidators(this.validation[control]);
      this.businessForm.get(control).updateValueAndValidity();
    }
  }

  onCompanyNameChange() {
    this.businessForm.get('businessName').clearValidators();
    this.businessForm.get('businessName').setValidators(this.validation['required']);
    this.businessForm.get('businessName').updateValueAndValidity();
    this.validationCheck();
  }

  onCompanyPostcodeChange() {
    this.businessForm.get('businessPostcode').clearValidators();
    this.businessForm.get('businessPostcode').setValidators(this.validation['businessPostcode']);
    this.businessForm.get('businessPostcode').updateValueAndValidity();
    this.validationCheck();
  }

  onCompanyABNChange() {
    this.businessForm.get('abn').clearValidators();
    this.businessForm.get('abn').setValidators(this.validation['abn']);
    this.businessForm.get('abn').updateValueAndValidity();
    this.validationCheck();
  }

  onCompanyASICChange() {
    this.businessForm.get('asic').clearValidators();
    this.businessForm.get('asic').setValidators(this.validation['asic']);
    this.businessForm.get('asic').updateValueAndValidity();
    this.validationCheck();
  }

  public resetResultObjects(): void {
    this.businessSearchResultData = new BusinessSearchResultModel();
    this.disableExitEventEmitter.emit(false);
  }


  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public businessClearSearch(): void {
    for (const control in this.businessForm.controls) {
      this.businessForm.get(control).clearValidators();
      this.businessForm.get(control).updateValueAndValidity();
    }
    //this.businessForm.reset();
    this.businessForm.patchValue({
      reference: "",
      businessName: "",
      businessPostcode: "",
      abn: "",
      asic: "",
    });
    this.showResultTab = false;
    // this.resetResultObjects();
  }

    public getLicenceItems(): void {
      this.searchService.AllowedLicence().subscribe(data => {
        this.getSecondAccess(data);
      });
    }
  
    public getSecondAccess(Items: Item[]) {
      Items.forEach(item => {
        if (item.licenceType.toLowerCase() == "search") {
          switch ((item.key)) {
            case "HUTDB": {
              this.accessData.isHUTDatabaseAccess = item.defaultValue;
              this.accessData.isHUTDatabaseToggle = item.toggle;
              break;
            }
            case "MOBILE": {
              this.accessData.isMobileAccess = item.defaultValue;
              this.accessData.isMobileToggle = item.toggle;
              break;
            }
            case "EMAIL": {
              this.accessData.isEmailAccess = item.defaultValue;
              this.accessData.isEmailToggle = item.toggle;
              break;
            }
            case "IDENTITY": {
              this.accessData.isIdentityAccess = item.defaultValue;
              this.accessData.isIdentityToggle = item.toggle;
              break;
            }
            case "FINANCIAL": {
              this.accessData.isFinancialAccess = item.defaultValue;
              this.accessData.isFinancialToggle = item.toggle;
              break;
            }
            case "ADDRESS": {
              this.accessData.isAddressAccess = item.defaultValue;
              this.accessData.isAddressToggle = item.toggle;
              break;
            }
            case "CRIMINALITY": {
              this.accessData.isCriminalityAccess = item.defaultValue;
              this.accessData.isCriminalityToggle = item.toggle;
              break;
            }
            case "SOCIAL": {
              this.accessData.isSocialMediaAccess = item.defaultValue;
              this.accessData.isSocialMediaToggle = item.toggle;
              break;
            }
            case "COUNTER_FRAUD": {
              this.accessData.isCounterFraudAccess = item.defaultValue;
              this.accessData.isCounterFraudToggle = item.toggle;
              break;
            }
            case "SPORTS": {
              this.accessData.isSportsAccess = item.defaultValue;
              this.accessData.isSportsToggle = item.toggle;
              break;
            }
            case "ADVERT": {
              this.accessData.isAdvertsAccess = item.defaultValue;
              this.accessData.isAdvertsToggle = item.toggle;
              break;
            }
            case "COMPANY_HOUSE": {
              this.accessData.isCompaniesHouseAccess = item.defaultValue;
              this.accessData.isCompaniesHouseToggle = item.toggle;
              break;
            }
            case "AVA": {
              this.accessData.isAVAAccess = item.defaultValue;
              this.accessData.isAVAToggle = item.toggle;
              break;
            }
            case "AML": {
              this.accessData.isAMLAccess = item.defaultValue;
              this.accessData.isAMLToggle = item.toggle;
              break;
            }
            case "CCJ_Detail": {
              this.accessData.isCCJDetailAccess = item.defaultValue;
              this.accessData.isCCJDetailToggle = item.toggle;
              break;
            }
            case "Insolvency_Detail": {
              this.accessData.isInsolvencyDetailAccess = item.defaultValue;
              this.accessData.isInsolvencyDetailToggle = item.toggle;
              break;
            }
            case "RTW": {
              this.accessData.isRTWAccess = item.defaultValue;
              this.accessData.isRTWToggle = item.toggle;
              break;
            }
            case "IDCHECK": {
              this.accessData.isIDCheckAccess = item.defaultValue;
              this.accessData.isIDCheckToggle = item.toggle;
              break;
            }
            case "LANDREGISTRY": {
              this.accessData.isLandRegistryAccess = item.defaultValue;
              this.accessData.isLandRegistryToggle = item.toggle;
              break;
            }
          }
        }
      });
    }

    public setActiveResultTab() {
      setTimeout(() => {
        // Tab Header
        var navLine = $('.nav-link');
        $.each(navLine, function (index, x) {
          if ($(x).hasClass("active")) {
            $(x).removeClass("active")
          }
        })
  
        // Tab Result
        var tabPane = $('.tab-pane');
        $.each(tabPane, function (index, x) {
          if ($(x).hasClass("active")) {
            $(x).removeClass("active")
          }
        })
  
        // Show first tab as active
        $('#business-search-tab').addClass("active");
        $('#businessSearch').addClass("active");
      }, 2000);
    }

  // TODO: this validation need to done through custom validation
  public validationCheck() {

    let companyPostcode = this.businessForm.get('businessPostcode').value;
    let companyName = this.businessForm.get('businessName').value;
    let companyABN = this.businessForm.get('abn').value;
    let companyASIC = this.businessForm.get('asic').value;
  
    if (companyPostcode && companyName) {
      this.businessForm.get('businessPostcode').clearValidators();
      this.businessForm.get('businessPostcode').setValidators([this.validation['businessPostcode']]);
      this.businessForm.get('businessPostcode').updateValueAndValidity();
  
      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').updateValueAndValidity();

      this.businessForm.get('abn').clearValidators();
      this.businessForm.get('abn').updateValueAndValidity();

      this.businessForm.get('asic').clearValidators();
      this.businessForm.get('asic').updateValueAndValidity();
  
      return;
    } 

    if(companyPostcode && !companyName) {
      this.businessForm.get('businessPostcode').clearValidators();
      this.businessForm.get('businessPostcode').setValidators([this.validation['businessPostcode']]);
      this.businessForm.get('businessPostcode').updateValueAndValidity();

      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').setValidators([this.validation['required']]);
      this.businessForm.get('businessName').updateValueAndValidity();

      this.businessForm.get('abn').clearValidators();
      this.businessForm.get('abn').updateValueAndValidity();
      this.businessForm.get('asic').clearValidators();
      this.businessForm.get('asic').updateValueAndValidity();
    } else {
      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').updateValueAndValidity();

      this.businessForm.get('businessPostcode').clearValidators();
      this.businessForm.get('businessPostcode').setValidators([this.validation['required']]);
      this.businessForm.get('businessPostcode').updateValueAndValidity();

      this.businessForm.get('abn').clearValidators();
      this.businessForm.get('abn').updateValueAndValidity();
      this.businessForm.get('asic').clearValidators();
      this.businessForm.get('asic').updateValueAndValidity();
    }
    
    
    if (!companyPostcode && !companyName) {
      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').updateValueAndValidity();

      this.businessForm.get('businessPostcode').clearValidators();
      this.businessForm.get('businessPostcode').updateValueAndValidity();
      if(companyABN || companyASIC) {
          if(companyABN) 
          {
            this.businessForm.get('abn').clearValidators();
            this.businessForm.get('abn').setValidators([this.validation['abn']]);
            this.businessForm.get('abn').updateValueAndValidity();
    
            this.businessForm.get('asic').clearValidators();
            this.businessForm.get('asic').updateValueAndValidity();
          } else {
            this.businessForm.get('asic').clearValidators();
            this.businessForm.get('asic').setValidators([this.validation['required'],this.validation['asic']]);
            this.businessForm.get('asic').updateValueAndValidity();
    
            this.businessForm.get('abn').clearValidators();
            this.businessForm.get('abn').updateValueAndValidity();
          }
      } else {
        this.businessForm.get('abn').clearValidators();
        this.businessForm.get('abn').setValidators([this.validation['required']]);
        this.businessForm.get('abn').updateValueAndValidity();

        this.businessForm.get('asic').clearValidators();
        this.businessForm.get('asic').setValidators([this.validation['required']]);
        this.businessForm.get('asic').updateValueAndValidity();
      }
    } 
  }
}
