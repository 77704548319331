import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';

@Component({
  selector: 'app-ltd-shares-business',
  templateUrl: './ltd-shares-business.component.html',
  styleUrls: ['./ltd-shares-business.component.css']
})
export class LtdSharesBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
