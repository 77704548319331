import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';
import { SearchService } from '../../../../../../services/search.service';

@Component({
  selector: 'app-ltd-registeration-business',
  templateUrl: './ltd-registeration-business.component.html',
  styleUrls: ['./ltd-registeration-business.component.css']
})
export class LtdRegisterationBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();
  public APIKey: string = null;

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
  }


  streetViewUrl(address: string): string {
    const encodedAddress = encodeURIComponent(address);
    return `https://maps.googleapis.com/maps/api/streetview?size=600x300&location==${encodedAddress}&key=AIzaSyD6bQM-KV4Ul5Fcycmlzu7q4gWGJ05lraE`;
  }

}
