import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';

@Component({
  selector: 'app-business-result-business',
  templateUrl: './business-result-business.component.html',
  styleUrls: ['./business-result-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class BusinessResultBusinessComponent implements OnInit {

  @Input() business: BusinessResultModel = new BusinessResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
