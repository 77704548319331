import { Component, Input, OnInit } from '@angular/core';
import { LandRegistryResultModel } from '../../../../../models/LandRegistryResultModel';
import { MessagingService } from 'src/app/demo/V3/services/messaging.service';

@Component({
  selector: 'app-landregistry-result-business',
  templateUrl: './landregistry-result-business.component.html',
  styleUrls: ['./landregistry-result-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class LandregistryResultBusinessComponent implements OnInit {

  @Input() landRegistry: LandRegistryResultModel = new LandRegistryResultModel();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

}
