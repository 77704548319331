<section id="business-search" class="search">
    <div class="container">
        <div class="logo-div d-flex justify-content-center">
            <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-business-RO.png" />
        </div>
        <form role="form" [formGroup]="businessForm">
            <div class="form-group row form-top-margin">
                <div class="col-md-9 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
                    <div class="row padding-form-design">
                        <div class="col-md-12 z-index-formdata">
                            <div class="row">
                                <div class="input-group">
                                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                                    <b>Reference:</b>
                                    </div>
                                    <div class="col-md-8 pt-3">
                                    <div class="form-group">
                                        <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                                        <p class="text-danger" *ngIf="businessForm.get('reference').errors?.required">
                                            <strong><small>Reference is required</small></strong>
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 z-index-formdata">
                            <div class="row">
                                <div class="input-group">
                                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                                    <b>Business Name:</b>
                                    </div>
                                    <div class="col-md-8 pt-3">
                                    <div class="form-group">
                                        <input type="text" placeholder="" class="form-control" formControlName="businessName" autocomplete="off" (input)="onCompanyNameChange()"/>
                                        <p class="non-limited-label">Enter Business Name and Postcode, ABN or ASIC </p>
                                        <p class="text-danger" *ngIf="businessForm.get('businessName').errors?.required">
                                        <strong><small>{{messagingService.BusinessNameRequiredMessage}}</small></strong>
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 z-index-formdata">
                            <div class="row">
                                <div class="input-group">
                                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-2">
                                    <b>Postcode:</b>
                                    </div>
                                    <div class="col-md-8">
                                    <div class="form-group">
                                        <input type="text" placeholder="" class="form-control" formControlName="businessPostcode" autocomplete="off" (input)="onCompanyPostcodeChange()"/>
                                        <p class="text-danger" *ngIf="businessForm.get('businessPostcode').errors?.required">
                                        <strong><small>{{messagingService.PostCodeRequiredMessage}}</small></strong>
                                        </p>
                                        <p class="text-danger" *ngIf="businessForm.get('businessPostcode').errors?.pattern">
                                        <strong><small>{{messagingService.BusinessPostcodeNotValid}}</small></strong>
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 z-index-formdata">
                            <div class="row">
                                <div class="input-group">
                                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                                    <b>ABN:</b>
                                    </div>
                                    <div class="col-md-8 mt-3">
                                    <div class="form-group">
                                        <input type="text" placeholder="" class="form-control" formControlName="abn" autocomplete="off" (keypress)="restrictCharacters($event)" (input)="onCompanyABNChange()"/>
                                        <p class="text-danger" *ngIf="businessForm.get('abn').errors?.required">
                                        <strong><small>{{messagingService.BusinessABNRequiredMessage}}</small></strong>
                                        </p>
                                        <p class="text-danger" *ngIf="businessForm.get('abn').errors?.pattern">
                                        <strong><small>{{messagingService.BusinessABNValidateMessage}}</small></strong>
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 z-index-formdata">
                            <div class="row">
                                <div class="input-group">
                                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                                    <b>ASIC:</b>
                                    </div>
                                    <div class="col-md-8 mt-3">
                                    <div class="form-group">
                                        <input type="text" placeholder="" class="form-control" formControlName="asic" autocomplete="off"  (keypress)="restrictCharacters($event)" (input)="onCompanyASICChange()"/>
                                        <p class="text-danger" *ngIf="businessForm.get('asic').errors?.required">
                                        <strong><small>{{messagingService.BusinessASICRequiredMessage}}</small></strong>
                                        </p>
                                        <p class="text-danger" *ngIf="businessForm.get('asic').errors?.pattern">
                                        <strong><small>{{messagingService.BusinessASICValidateMessage}}</small></strong>
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 padding-form-design pb-2">
                            <div class="row">
                                <div class="input-group">
                                    <div class="col-md-12 offset-md-3">
                                    <div class="form-group">
                                        <button type="submit" class="btn colorRed mx-2" (click)="businessSearch()">Search <i class="fa fa-search" aria-hidden="true"></i></button>
                                        <!-- <button type="button" class="btn colorRed btn-pdf mx-2" 
                                        *ngIf="saveAsPDFButtonVisibility"
                                        [disabled]="businessSearchResultData?.isLoading">
                                        Save as PDF
                                        </button> -->
                                        <button class="btn colorRed reset-btn mx-2" 
                                        *ngIf="clearSearchButtonVisibility"
                                        (click)="businessClearSearch()"
                                        [disabled]="businessSearchResultData?.isLoading">
                                        Clear Search
                                        </button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div>
            <div class="container" *ngIf="showResultTab">
                <div class="mb-5">
                    <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
                        <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="business-search-tab" data-toggle="tab" href="#businessSearch" role="tab"
                                aria-controls="profile" aria-selected="false"><b>Business</b></a>
                            </li>
                        </ul>
        
                    <div class="tab-content" id="searchResultTabContent">
                        <!-- Business Result -->
                        <div class="tab-pane fade show tab-height active" id="businessSearch" role="tabpanel" aria-labelledby="business-search-tab">
                            <app-au-business-result [businessResultDataModel]="businessSearchResultData" [searchType]="searchType"></app-au-business-result>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="pt-1"></div>
    </div>
</section>