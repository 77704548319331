export class ProfileData {
  referenceNumber: string;
  phoneNumber: string;
  fullName: string;
  firstName: string;
  midleName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  streetAddress?: string;
  suburb?: string;
  city: string;
  postalCode: string;
  abn: string;
  asic: string;
  state: string;
  country: string;
  dateOfBirth: string;
  companyName: string;
  companyNumber: string;
  companyPostalCode: string;
  vatNumber: string;
  vRN: string;
  email: string;
  associatedNames: any[] = [];
  category: string;
  registerNumber: string;
  auditKey: string;
  searchType: string;
  searchDate: string;
  searchTime: string;
  searchUsername: string;
  departmentId: number;
}

export class AccessData {
  isHUTDatabaseAccess: boolean = false;
  isHUTDatabaseToggle: boolean = false;
  isMobileAccess: boolean = false;
  isMobileToggle: boolean = false;
  isEmailAccess: boolean = false;
  isEmailToggle: boolean = false;
  isIdentityAccess: boolean = false;
  isIdentityToggle: boolean = false;
  isFinancialAccess: boolean = false;
  isFinancialToggle: boolean = false;
  isAddressAccess: boolean = false;
  isAddressToggle: boolean = false;
  isCriminalityAccess: boolean = false;
  isCriminalityToggle: boolean = false;
  isSocialMediaAccess: boolean = false;
  isSocialMediaToggle: boolean = false;
  isCounterFraudAccess: boolean = false;
  isCounterFraudToggle: boolean = false;
  isSportsAccess: boolean = false;
  isSportsToggle: boolean = false;
  isAdvertsAccess: boolean = false;
  isAdvertsToggle: boolean = false;
  isCompaniesHouseAccess: boolean = false;
  isCompaniesHouseToggle: boolean = false;
  isAVAAccess: boolean = false;
  isAVAToggle: boolean = false;
  isAMLAccess: boolean = false;
  isAMLToggle: boolean = false;
  isCCJDetailAccess: boolean = false;
  isCCJDetailToggle: boolean = false;
  isInsolvencyDetailAccess: boolean = false;
  isInsolvencyDetailToggle: boolean = false;
  isRTWAccess: boolean = false;
  isRTWToggle: boolean = false;
  isRTWNoFormsAccess: boolean = false;
  isIDCheckAccess: boolean = false;
  isIDCheckToggle: boolean = false;
  isLandRegistryAccess: boolean = false;
  isLandRegistryToggle: boolean = false;
  isQuickSearchEnabled: boolean = false;
}

export class RequestData {
  profileData: ProfileData = new ProfileData();
  accessData: AccessData = new AccessData();
}

// export class AustraliaRequestData {
//   profileData: AustraliaProfileData = new AustraliaProfileData();
// }

// export class AustraliaProfileData {
//   auditKey: string;
//   referenceNumber: string;
//   fullName: string;
//   firstName: string;
//   midleName: string;
//   lastName: string;
//   dateOfBirth: string;
//   phoneNumber: string;
//   email: string;
//   streetAddress: string;
//   suburb: string;
//   state: string;
//   postcode: string;

//   searchType: string;
//   searchDate: string;
//   searchTime: string;
//   searchUsername: string;
// }