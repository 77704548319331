<div class="container pb-4">
  <div>
    <div class="col-md-12" *ngIf="!mobile.isLoading && !mobile.validationError && mobile.searchFail && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
    <div class="col-md-12" *ngIf="!mobile.isLoading && !mobile.validationError && !mobile.responseData?.data && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
    <div class="col-md-12" *ngIf="!mobile.isLoading && !mobile.validationError && mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
    <div class="col-md-12" *ngIf="!mobile.isLoading && mobile.validationError && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.MobileRequiredMessage}}</i></div>
  </div>
  
  <div class="loadingImg" *ngIf="mobile.isLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </div>

  <div *ngIf="!mobile.isLoading && mobile.noSearchSelect == false && !mobile.validationError && !mobile.searchFail">
    <div class="keep-together">
      <div><b><u>MOBILE:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="mobile.searchFail && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="!mobile.responseData?.data && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
        <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
      </div>
      <div *ngIf="mobile.responseData?.data && !mobile.searchFail">
        <div class="row" *ngIf="mobile.responseData?.data?.fullNumber">
          <div class="col-md-4">Mobile Phone Number:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.fullNumber}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.carrier">
          <div class="col-md-4">Carrier:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.carrier}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.country">
          <div class="col-md-4">Country:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.country}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.code">
          <div class="col-md-4">Country Code:</div>
          <div class="col-md-8">{{mobile.responseData?.data.code}}</div>
        </div>
      </div>
    </div>

    <br />

    <div class="keep-together">
      <div><b><u>LIVENESS:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="mobile.searchFail && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="!mobile.responseData?.data?.phoneIdLiveCheck && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
        <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
      </div>
      <div *ngIf="mobile.responseData?.data?.phoneIdLiveCheck && !mobile.searchFail">
        <!--<div class="row" *ngIf="mobile.responseData?.data?.contact?.first_name">
          <div class="col-md-4">First name:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.contact.first_name}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.contact?.last_name">
          <div class="col-md-4">Last name:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.contact.last_name}}</div>
        </div>-->
        <div class="row" *ngIf="mobile.responseData?.data?.phoneIdLiveCheck?.subscriber_status">
          <div class="col-md-4">Subscriber status:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.phoneIdLiveCheck.subscriber_status}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.phoneIdLiveCheck?.device_status">
          <div class="col-md-4">Device status:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.phoneIdLiveCheck.device_status}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.phoneIdLiveCheck?.roaming">
          <div class="col-md-4">Roaming:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.phoneIdLiveCheck.roaming}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.phoneIdLiveCheck?.roaming_country">
          <div class="col-md-4">Roaming country:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.phoneIdLiveCheck.roaming_country}}</div>
        </div>
      </div>
    </div>

    <br />

    <div class="keep-together">
      <div><b><u>SIM:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="mobile.searchFail && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
      </div>
      <div *ngIf="!mobile.searchFail">
        <div class="row" *ngIf="mobile.responseData?.data?.blockedDescription">
          <div class="col-md-4">Block Description:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.blockedDescription}}</div>
        </div>
        <!--<div class="row" *ngIf="mobile.responseData?.data?.simSwap?.date">
          <div class="col-md-4">Last Sim Swap Data:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.simSwap?.date | date:'dd-MM-yyyy'}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.simSwap?.date">
          <div class="col-md-4">Last Sim Swap Time:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.simSwap?.date | date:'hh:mm:ss'}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.simSwap?.riskIndicatorStatus">
          <div class="col-md-4">Sim Swap Risk:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.simSwap?.riskIndicatorStatus}}</div>
        </div>-->
      </div>
    </div>

    <br />

    <div class="keep-together">
      <div><b><u>RISK:</u></b></div>
      <div>
        <div class="col-md-12" *ngIf="mobile.searchFail && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
        <div class="col-md-12" *ngIf="(!mobile.responseData?.data?.phoneScoreCheck || !mobile.responseData?.data?.phoneScoreCheck?.risk) && !mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
        <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
      </div>
      <div *ngIf="mobile.responseData?.data?.phoneScoreCheck && mobile.responseData?.data?.phoneScoreCheck?.risk && !mobile.searchFail">
        <div class="row" *ngIf="mobile.responseData?.data?.phoneScoreCheck?.risk?.score">
          <div class="col-md-4">Overall Risk score:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.phoneScoreCheck?.risk?.score}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.phoneScoreCheck?.risk?.level">
          <div class="col-md-4">Overall Risk Level:</div>
          <div class="col-md-8">{{mobile.responseData?.data?.phoneScoreCheck?.risk?.level}}</div>
        </div>
        <div class="row" *ngIf="mobile.responseData?.data?.phoneScoreCheck?.statusCodeMessage">
          <div class="col-md-4">Risk Reason(s):</div>
          <div class="col-md-8">
            <div *ngFor="let codeMessage of mobile.responseData?.data?.phoneScoreCheck?.statusCodeMessage">
              {{ codeMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
