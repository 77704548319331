import { Component , OnInit } from '@angular/core';
import { SearchService } from '../../../services/search.service';
import { TenantRegion } from '../../models/TenantRegionResposne';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {
 public regionAccess: TenantRegion = new TenantRegion();
   public isExitDisabled: boolean = false;
   
   constructor(private searchService: SearchService) { }
 
   ngOnInit(): void {
     this.getTenantRegion();
   }
 
   public receiveDisableExit($event: boolean) {
     this.isExitDisabled = $event
   }
 
   public getTenantRegion() {
     this.searchService.GetTenantRegion().subscribe(data => {
       this.regionAccess = data;
     }, error => {
       console.log(error);
     });
   }
}
