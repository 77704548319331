import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';

@Component({
  selector: 'app-non-ltd-business',
  templateUrl: './non-ltd-business.component.html',
  styleUrls: ['./non-ltd-business.component.css']
})
export class NonLtdBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

  streetViewUrl(businessLocation: any) {
    const addressLines = [
      businessLocation.LocationLine1,
      businessLocation.LocationLine2,
      businessLocation.LocationLine3,
      businessLocation.LocationLine4,
      businessLocation.LocationLine5,
      businessLocation.Postcode
    ];
    const fullAddress = addressLines.filter(line => line?.trim()).join(", ");
    const encodedAddress = encodeURIComponent(fullAddress);
    return `https://maps.googleapis.com/maps/api/streetview?size=600x300&location==${encodedAddress}&key=AIzaSyD6bQM-KV4Ul5Fcycmlzu7q4gWGJ05lraE`;
  }

}
