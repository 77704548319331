import { Component, Input, OnInit } from '@angular/core';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';

@Component({
  selector: 'app-ltd-officers-business',
  templateUrl: './ltd-officers-business.component.html',
  styleUrls: ['./ltd-officers-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class LtdOfficersBusinessComponent implements OnInit {

  @Input() public business: BusinessResultModel = new BusinessResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
