<div class="container mb-5">
    <div class="mb-5">
        <div class="col-md-12" *ngIf="!businessResultDataModel?.isLoading && businessResultDataModel?.noRelevantData"><i class="color-grey float-right">{{messagingService.NoRelevantDataMessage}}</i></div>
    </div>
    <span class="loadingImg" *ngIf="businessResultDataModel?.isLoading">
        <img src="{{messagingService.loadingAnimation}}" />
    </span>

    <div *ngIf="!businessResultDataModel?.isLoading && !businessResultDataModel.searchFail && businessResultDataModel?.responseData?.response && businessResultDataModel?.responseData?.response?.searchResultsList?.searchResultsRecord != null && searchType == 'ABRSearchByName'">
        <!-- <pre>{{businessResultDataModel?.responseData?.data | json}}</pre> -->
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-4"
                    *ngFor="let result of businessResultDataModel?.responseData?.response?.searchResultsList?.searchResultsRecord">
                    <div class="bg-white h-100 keep-together">
                        <div class="address-box-design border-bottom p-2 border border-primary shadow">
                            <div class="container mt-4">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr *ngIf="result?.ABN?.identifierValue">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">ABN:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.ABN?.identifierValue }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.ABN?.identifierStatus">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">Status:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.ABN?.identifierStatus }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.mainName?.organisationName">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">Organization:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.mainName?.organisationName }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.mainName?.score">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">Confidenece Score (%):</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.mainName?.score }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.mainName?.isCurrentIndicator">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">Current Name:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.mainName?.isCurrentIndicator }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.mainBusinessPhysicalAddress?.stateCode">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">State:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.mainBusinessPhysicalAddress?.stateCode }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.mainBusinessPhysicalAddress?.postcode">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">Postcode:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.mainBusinessPhysicalAddress?.postcode }}</p></td>
                                        </tr>
                                        <tr *ngIf="result?.mainBusinessPhysicalAddress?.isCurrentIndicator">
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">Current Address?:</p></td>
                                            <td style="width: 200px;" class="p-1"><p class="mb-0">{{ result?.mainBusinessPhysicalAddress?.isCurrentIndicator }}</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!businessResultDataModel?.isLoading && !businessResultDataModel.searchFail && businessResultDataModel?.responseData?.response && businessResultDataModel?.responseData?.response?.businessEntity202001 != null && searchType == 'SearchByABN'">
        <!-- <pre>{{businessResultDataModel?.responseData?.data | json}}</pre> -->
        <div class="container">
            <div class="col-md-12">
                <div class="row form-data-background">
                    <p class="text-left m-2">ABN  Details</p>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Updated:
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.recordLastUpdatedDate | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                   
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Entity Type:
                        </div>
                        <div class="col">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.entityType?.entityDescription }} 
                        </div>
                        <div class="col">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          ASIC Number:
                        </div>
                        <div class="col">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.ASICNumber}}
                        </div>
                        <div class="col">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          ABN
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col">
                         
                        </div>
                    </div>
                    <!-- <p>{{ abns | json}} </p> -->
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.ABN">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.ABN)">
                        <div class="row" *ngFor="let abn of businessResultDataModel?.responseData?.response?.businessEntity202001?.ABN">
                          <div class="col-5 pl-1">
                            {{ abn.identifierValue }}
                          </div>
                          <div class="col">
                            {{ (abn.replacedFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.ABN)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.ABN.identifierValue }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.ABN.replacedFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Entity Name
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName)">
                        <div class="row" *ngFor="let main of businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName">
                          <div class="col-5 pl-1">
                            {{ main?.organisationName }}
                          </div>
                          <div class="col">
                            {{ (main?.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (main?.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName?.organisationName }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName?.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.mainName?.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div> 

                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.legalName">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.legalName)">
                        <div class="row" *ngFor="let legal of businessResultDataModel?.responseData?.response?.businessEntity202001?.legalName">
                          <div class="col-5 pl-1">
                            {{ getLegalNames(legal) }}
                          </div>
                          <div class="col">
                            {{ (legal.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (legal.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.legalName)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ getLegalNames() }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          ABN Status
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus)">
                        <div class="row"  *ngFor="let entity of businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus">
                          <div class="col-5 pl-1">
                            {{ entity.entityStatusCode }}
                          </div>
                          <div class="col">
                            {{ (entity.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (entity.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus.entityStatusCode }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.entityStatus.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Goods & Services Tax (GST)
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.goodsAndServicesTax">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.goodsAndServicesTax)">
                        <div class="row" *ngFor="let serviceTax of businessResultDataModel?.responseData?.response?.businessEntity202001?.goodsAndServicesTax">
                          <div class="col-5 pl-1">
                            
                          </div>
                          <div class="col">
                            {{ (serviceTax.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (serviceTax.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.goodsAndServicesTax)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.goodsAndServicesTax.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.goodsAndServicesTax.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Main Business Location
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <ng-container *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.mainBusinessPhysicalAddress">
                      <div class="row" *ngFor="let mainAddress of businessResultDataModel?.responseData?.response?.businessEntity202001?.mainBusinessPhysicalAddress">
                        <div class="col-5 pl-1">
                          {{ mainAddress.stateCode + '/' + mainAddress.postcode }}
                        </div>
                        <div class="col">
                          {{ (mainAddress.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (mainAddress.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                </div>
                <div class="row form-data-background">
                    <p class="text-left m-2">Business Name(s)</p>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Business Name
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                </div>
                <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName">
                  <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName)">
                    <div class="row" *ngFor="let business of businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName">
                      <div class="col-5 pl-1">
                        {{ business.organisationName }}
                      </div>
                      <div class="col">
                        {{ (business.effectiveFrom | date: 'dd-MM-yyyy') }}
                      </div>
                      <div class="col">
                        {{ (business.effectiveTo | date: 'dd-MM-yyyy') }}
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName)">
                    <div class="row">
                      <div class="col-5 pl-1">
                        {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName.organisationName }}
                      </div>
                      <div class="col">
                        {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName.effectiveFrom | date: 'dd-MM-yyyy') }}
                      </div>
                      <div class="col">
                        {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.businessName.effectiveTo | date: 'dd-MM-yyyy') }}
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="row form-data-background">
                    <p class="text-left m-2">Trading Name(s)</p>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Trading Name
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName)">
                        <div class="row" *ngFor="let mainTrading of businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName">
                          <div class="col-5 pl-1">
                            {{ mainTrading.organisationName }}
                          </div>
                          <div class="col">
                            {{ (mainTrading.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (mainTrading.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName.organisationName }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.mainTradingName.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div >
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName)">
                        <div class="row" *ngFor="let otherTrading of businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName">
                          <div class="col-5 pl-1">
                            {{ otherTrading.organisationName }}
                          </div>
                          <div class="col">
                            {{ (otherTrading.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (otherTrading.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName.organisationName }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.otherTradingName.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                </div>
                <div class="row form-data-background">
                    <p class="text-left m-2">Charity Tax Concession Status</p>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Charity/Fund Type
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType?.charityTypeDescription">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType)">
                        <div class="row" *ngFor="let type of businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType">
                          <div class="col-5 pl-1">
                            {{ type.charityTypeDescription }}
                          </div>
                          <div class="col">
                            {{ (type.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (type.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType.charityTypeDescription }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.charityType.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Tax Concessions
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement)">
                        <div class="row" *ngFor="let tax of businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement">
                          <div class="col-5 pl-1">
                            {{ tax.endorsementType }}
                          </div>
                          <div class="col">
                            {{ (tax.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (tax.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement.endorsementType }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.taxConcessionCharityEndorsement.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Public Benevolent Institution (PBI) Employer Name
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.publicBenevolentInstitutionEmployer">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.publicBenevolentInstitutionEmployer)">
                        <div class="row" *ngFor="let employer of businessResultDataModel?.responseData?.response?.businessEntity202001?.publicBenevolentInstitutionEmployer">
                          <div class="col-5 pl-1">
                            {{ employer.organisationName }}
                          </div>
                          <div class="col-5">
                            {{ (employer.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col-5">
                            {{ (employer.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.publicBenevolentInstitutionEmployer)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.organisationName }}
                          </div>
                          <div class="col-5">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col-5">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                </div>
                <div class="row form-data-background">
                    <p class="text-left m-2">Deductible Gift Recipient Status</p>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          Fund,Authority or Institution Name
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund)">
                        <div class="row" *ngFor="let fund of businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund">
                          <div class="col-5 pl-1">
                            {{ fund?.dgrFundName?.organisationName }}
                          </div>
                          <div class="col">
                            {{ (fund?.endorsedFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (fund?.endorsedTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund?.dgrFundName?.organisationName }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund?.endorsedFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrFund?.endorsedTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row" >
                      <div class="col-5 pl-1 text-blue">
                        Entity Endorsement
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement)">
                        <div class="row" *ngFor="let dgr of businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement">
                          <div class="col-5 pl-1">
                            {{ dgr.entityEndorsement }}
                          </div>
                          <div class="col">
                            {{ (dgr.endorsedFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (dgr.endorsedTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement.entityEndorsement }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement.endorsedFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.dgrEndorsement.endorsedTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                </div>
                <div class="row form-data-background">
                    <p class="text-left m-2">Australian Charities and Not-for-profits Commission(ACNC)</p>
                </div>
                <div class="col-md-12 px-0">
                    <div class="row">
                        <div class="col-5 pl-1 text-blue">
                          ACNC registration
                        </div>
                        <div class="col text-blue">
                          From
                        </div>
                        <div class="col text-blue">
                          To
                        </div>
                    </div>
                    <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration">
                      <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration)">
                        <div class="row" *ngFor="let acnc of businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration">
                          <div class="col-5 pl-1 text-blue">
                            {{ acnc.status }}
                          </div>
                          <div class="col text-blue">
                            {{ (acnc.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col text-blue">
                            {{ (acnc.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration)">
                        <div class="row">
                          <div class="col-5 pl-1">
                            {{ businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration.status }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration.effectiveFrom | date: 'dd-MM-yyyy') }}
                          </div>
                          <div class="col">
                            {{ (businessResultDataModel?.responseData?.response?.businessEntity202001?.ACNCRegistration.effectiveTo | date: 'dd-MM-yyyy') }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!businessResultDataModel?.isLoading && !businessResultDataModel.searchFail && businessResultDataModel?.responseData?.response && businessResultDataModel?.responseData?.response?.businessEntity201408 != null && searchType == 'SearchByASIC'">
      <!-- <pre>{{businessResultDataModel?.responseData?.data | json}}</pre> -->
      <div class="container">
          <div class="col-md-12">
              <div class="row form-data-background">
                  <p class="text-left m-2">ABN  Details</p>
              </div>
              <div class="col-md-12 px-0">
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Updated:
                      </div>
                      <div class="col">
                        {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.recordLastUpdatedDate | date: 'dd-MM-yyyy') }}
                      </div>
                      <div class="col">
                 
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Entity Type:
                      </div>
                      <div class="col">
                        {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.entityType?.entityDescription }} 
                      </div>
                      <div class="col">

                      </div>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        ASIC Number:
                      </div>
                      <div class="col">
                        {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.ASICNumber}}
                      </div>
                      <div class="col">

                      </div>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        ABN
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col">
                       
                      </div>
                  </div>
                  <!-- <p>{{ abns | json}} </p> -->
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.ABN">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.ABN)">
                      <div class="row" *ngFor="let abn of businessResultDataModel?.responseData?.response?.businessEntity201408?.ABN">
                        <div class="col-5 pl-1">
                          {{ abn.identifierValue }}
                        </div>
                        <div class="col">
                          {{ (abn.replacedFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.ABN)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.ABN.identifierValue }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.ABN.replacedFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Entity Name
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName)">
                      <div class="row" *ngFor="let main of businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName">
                        <div class="col-5 pl-1">
                          {{ main?.organisationName }}
                        </div>
                        <div class="col">
                          {{ (main?.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (main?.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName?.organisationName }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName?.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.mainName?.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div> 

                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.legalName">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.legalName)">
                      <div class="row" *ngFor="let legal of businessResultDataModel?.responseData?.response?.businessEntity201408?.legalName">
                        <div class="col-5 pl-1">
                          {{ getLegalNames(legal) }}
                        </div>
                        <div class="col">
                          {{ (legal.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (legal.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.legalName)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ getLegalNames() }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        ABN Status
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus)">
                      <div class="row"  *ngFor="let entity of businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus.entityStatusCode }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus.entityStatusCode }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.entityStatus.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Goods & Services Tax (GST)
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.goodsAndServicesTax">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.goodsAndServicesTax)">
                      <div class="row" *ngFor="let serviceTax of businessResultDataModel?.responseData?.response?.businessEntity201408?.goodsAndServicesTax">
                        <div class="col-5 pl-1">
                          
                        </div>
                        <div class="col">
                          {{ (serviceTax.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (serviceTax.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.goodsAndServicesTax)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.goodsAndServicesTax.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.goodsAndServicesTax.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Main Business Location
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <ng-container *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.mainBusinessPhysicalAddress">
                    <div class="row" *ngFor="let mainAddress of businessResultDataModel?.responseData?.response?.businessEntity201408?.mainBusinessPhysicalAddress">
                      <div class="col-5 pl-1">
                        {{ mainAddress.stateCode + '/' + mainAddress.postcode }}
                      </div>
                      <div class="col">
                        {{ (mainAddress.effectiveFrom | date: 'dd-MM-yyyy') }}
                      </div>
                      <div class="col">
                        {{ (mainAddress.effectiveTo | date: 'dd-MM-yyyy') }}
                      </div>
                    </div>
                  </ng-container>
              </div>
              <div class="row form-data-background">
                  <p class="text-left m-2">Business Name(s)</p>
              </div>
              <div class="col-md-12 px-0">
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Business Name
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
              </div>
              <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName">
                <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName)">
                  <div class="row" *ngFor="let business of businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName">
                    <div class="col-5 pl-1">
                      {{ business.organisationName }}
                    </div>
                    <div class="col">
                      {{ (business.effectiveFrom | date: 'dd-MM-yyyy') }}
                    </div>
                    <div class="col">
                      {{ (business.effectiveTo | date: 'dd-MM-yyyy') }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName)">
                  <div class="row">
                    <div class="col-5 pl-1">
                      {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName.organisationName }}
                    </div>
                    <div class="col">
                      {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName.effectiveFrom | date: 'dd-MM-yyyy') }}
                    </div>
                    <div class="col">
                      {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.businessName.effectiveTo | date: 'dd-MM-yyyy') }}
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="row form-data-background">
                  <p class="text-left m-2">Trading Name(s)</p>
              </div>
              <div class="col-md-12 px-0">
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Trading Name
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName)">
                      <div class="row" *ngFor="let mainTrading of businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName">
                        <div class="col-5 pl-1">
                          {{ mainTrading.organisationName }}
                        </div>
                        <div class="col">
                          {{ (mainTrading.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (mainTrading.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName.organisationName }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.mainTradingName.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div >
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName)">
                      <div class="row" *ngFor="let otherTrading of businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName">
                        <div class="col-5 pl-1">
                          {{ otherTrading.organisationName }}
                        </div>
                        <div class="col">
                          {{ (otherTrading.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (otherTrading.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName.organisationName }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.otherTradingName.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div >
              </div>
              <div class="row form-data-background">
                  <p class="text-left m-2">Charity Tax Concession Status</p>
              </div>
              <div class="col-md-12 px-0">
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Charity/Fund Type
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType?.charityTypeDescription">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType)">
                      <div class="row" *ngFor="let type of businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType">
                        <div class="col-5 pl-1">
                          {{ type.charityTypeDescription }}
                        </div>
                        <div class="col">
                          {{ (type.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (type.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType.charityTypeDescription }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.charityType.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Tax Concessions
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement)">
                      <div class="row" *ngFor="let tax of businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement">
                        <div class="col-5 pl-1">
                          {{ tax.endorsementType }}
                        </div>
                        <div class="col">
                          {{ (tax.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (tax.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement.endorsementType }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.taxConcessionCharityEndorsement.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Public Benevolent Institution (PBI) Employer Name
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.publicBenevolentInstitutionEmployer">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.publicBenevolentInstitutionEmployer)">
                      <div class="row" *ngFor="let employer of businessResultDataModel?.responseData?.response?.businessEntity201408?.publicBenevolentInstitutionEmployer">
                        <div class="col-5 pl-1">
                          {{ employer.organisationName }}
                        </div>
                        <div class="col-5">
                          {{ (employer.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col-5">
                          {{ (employer.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.publicBenevolentInstitutionEmployer)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.organisationName }}
                        </div>
                        <div class="col-5">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col-5">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
              </div>
              <div class="row form-data-background">
                  <p class="text-left m-2">Deductible Gift Recipient Status</p>
              </div>
              <div class="col-md-12 px-0">
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        Fund,Authority or Institution Name
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund)">
                      <div class="row" *ngFor="let fund of businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund">
                        <div class="col-5 pl-1">
                          {{ fund?.dgrFundName?.organisationName }}
                        </div>
                        <div class="col">
                          {{ (fund?.endorsedFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (fund?.endorsedTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund?.dgrFundName?.organisationName }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund?.endorsedFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrFund?.endorsedTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row" >
                    <div class="col-5 pl-1 text-blue">
                      Entity Endorsement
                    </div>
                    <div class="col text-blue">
                      From
                    </div>
                    <div class="col text-blue">
                      To
                    </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement)">
                      <div class="row" *ngFor="let dgr of businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement">
                        <div class="col-5 pl-1">
                          {{ dgr.entityEndorsement }}
                        </div>
                        <div class="col">
                          {{ (dgr.endorsedFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (dgr.endorsedTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement.entityEndorsement }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement.endorsedFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.dgrEndorsement.endorsedTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
              </div>
              <div class="row form-data-background">
                  <p class="text-left m-2">Australian Charities and Not-for-profits Commission(ACNC)</p>
              </div>
              <div class="col-md-12 px-0">
                  <div class="row">
                      <div class="col-5 pl-1 text-blue">
                        ACNC registration
                      </div>
                      <div class="col text-blue">
                        From
                      </div>
                      <div class="col text-blue">
                        To
                      </div>
                  </div>
                  <div *ngIf="businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration">
                    <ng-container *ngIf="isArray(businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration)">
                      <div class="row" *ngFor="let acnc of businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration">
                        <div class="col-5 pl-1 text-blue">
                          {{ acnc.status }}
                        </div>
                        <div class="col text-blue">
                          {{ (acnc.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col text-blue">
                          {{ (acnc.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isObject(businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration)">
                      <div class="row">
                        <div class="col-5 pl-1">
                          {{ businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration.status }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration.effectiveFrom | date: 'dd-MM-yyyy') }}
                        </div>
                        <div class="col">
                          {{ (businessResultDataModel?.responseData?.response?.businessEntity201408?.ACNCRegistration.effectiveTo | date: 'dd-MM-yyyy') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>