import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-top-nav',
  templateUrl: './search-top-nav.component.html',
  styleUrls: ['./search-top-nav.component.css', '../shared/shared.searchpage.css']
})
export class SearchTopNavComponent implements OnInit {

  @Input() disableExit: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}
