import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { BusinessResultModel } from '../../../../../models/BusinessResultModel';
import { RequestData } from '../../../../../models/SearchRequestModel';

@Component({
  selector: 'app-dashboard-one-business',
  templateUrl: './dashboard-one-business.component.html',
  styleUrls: ['./dashboard-one-business.component.css', '../../../../shared/shared.searchpage.css']
})
export class DashboardOneBusinessComponent implements OnInit {

  @Input() business: BusinessResultModel = new BusinessResultModel();
  @Input() requestData: RequestData = new RequestData();

  constructor(private messageService: MessagingService) { }

  ngOnInit(): void {
  }

  public isLimitedCompany(): boolean {
    if (
      ((this.requestData.profileData.companyNumber && this.requestData.profileData.companyName) || (this.requestData.profileData.companyName && !this.requestData.profileData.companyNumber) || this.requestData.profileData.companyNumber) &&
      ((this.business.creditsafeResult && !this.business.businessNonRegisteredProfileResult) ||
        (this.business?.responseData?.businessTargeterObject && this.business?.responseData?.businessTargeterObject?.legalStatus == 'L'))
    ) {
      return true;
    }
    return false;
  }

  public isNonLimitedCompany(): boolean {
    if (
      (!this.requestData.profileData.companyNumber) &&
      ((this.business.businessNonRegisteredProfileResult && !this.business.creditsafeResult) ||
        (this.business?.responseData?.businessTargeterObject && this.business?.responseData?.businessTargeterObject?.legalStatus == 'N'))
    ) {
      return true;
    }
    return false;
  }

  public isNonLimitedCompanyExistence(): boolean {
    if (
      (!this.requestData.profileData.companyNumber) &&
      ((this.business.businessNonRegisteredProfileResult && !this.business.creditsafeResult) || (!this.business.businessNonRegisteredProfileResult && !this.business.creditsafeResult) ||
        (this.business?.responseData?.businessTargeterObject && this.business?.responseData?.businessTargeterObject?.legalStatus == 'N'))
    ) {
      return true;
    }
    return false;
  }

}
